import Doctor1 from "../../../assets/images/dr_1.jpg";
import Doctor2 from "../../../assets/images/dr_2.jpg";
import Asterisk from "../../../assets/images/asterisk_noborder.png";
import { Link } from "react-router-dom";

function Practitioners() {
  return (
    <div id="practitioners-wrap">
      <h1>Discover peace, passion and purpose</h1>
      <p>
        At LumiLife, we know that wellness means more than lifting dumbbells and
        eating your greens. It’s holistic. And it all starts with questions
        designed to assist with self-exploration and discovery. Meet the
        practitioners who care about you — who are ready and willing to work
        with you through the hard stuff to help you shine.
      </p>
      <img className="asterisk" src={Asterisk}></img>
      <h2>We're here for you</h2>

      <div id="practitioner-box" className="col-12">
        <div className="row">
          <div className="col-4">
            <div className="practitioner-card-container">
              <div className="practitioner-card">
                <div className="row m-0">
                  <div className="col-4">
                    <img src={Doctor1}></img>
                    <div className="top-badge ">TOP RATED</div>
                  </div>
                  <div className="col-8">
                    <div className="name">Dr. Hazel Helman</div>
                    <div className="position">Counsellor</div>
                    <div className="message">
                      Hi! I'm Kath, a qualified and experienced Psychologist. I
                      have over ten years experience working with adults and
                      adolesc
                    </div>
                    <div className="rate">
                      <span className="figure">AUD $120</span>
                      <span className="persession">per session</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-4">
            <div className="practitioner-card-container">
              <div className="practitioner-card ">
                <div className="row m-0">
                  <div className="col-4">
                    <img src={Doctor2}></img>
                    <div className="top-badge">TOP RATED</div>
                  </div>
                  <div className="col-8">
                    <div className="name">Dr. Lara Smith</div>
                    <div className="position">Counsellor</div>
                    <div className="message">
                      Hi! I'm Kath, a qualified and experienced Psychologist. I
                      have over ten years experience working with adults and
                      adolesc
                    </div>
                    <div className="rate">
                      <span className="figure">AUD $100</span>
                      <span className="persession">per session</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-4">
            <div className="practitioner-card-container">
              <div className="practitioner-card ">
                <div className="row m-0">
                  <div className="col-4">
                    <img src={Doctor1}></img>
                    <div className="top-badge">TOP RATED</div>
                  </div>
                  <div className="col-8">
                    <div className="name">Dr. Hazel Helman</div>
                    <div className="position">Counsellor</div>
                    <div className="message">
                      Hi! I'm Kath, a qualified and experienced Psychologist. I
                      have over ten years experience working with adults and
                      adolesc
                    </div>
                    <div className="rate">
                      <span className="figure">AUD $120</span>
                      <span className="persession">per session</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Link className="lumi-btn" to={"/browse-practitioners"}>
        Find your practitioner
      </Link>
    </div>
  );
}

export default Practitioners;
