import React from 'react';
import useDownloader from 'react-use-downloader';

function CertificationCard({ cert, isAdmin }) {
  const { download } =
  useDownloader();
  return (
    <>
      <li>
        <img src={cert} width="150" height="150" />
        {!isAdmin && (
          <button
            className="lumi-btn join-call padding-horizontal"
            onClick={() =>
              download(cert, cert.substring(cert.lastIndexOf("/") + 1))
            }
          >
            Download
          </button>
        )}
      </li>
    </>
  );
}

export default CertificationCard;
